.qaContainer {
  max-width: 90%;
  margin: 2rem auto;
}

.searchBar {
  margin-top: 2rem;
}

.submitQuestionSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: 2rem auto;
  padding: 1.75rem;
  background: #f5f5f5;
  border-radius: 0 0 20px 20px;
}

.inputQA {
  /* border: 1px solid #9D9D9D; */
  border-radius: 0.5rem;
  padding: 0.625rem 1rem;
  background: #ffffff;
  font-size: 0.8rem;
}

.selectQA {
  border: 1px solid #9d9d9d;
  border-radius: 0.5rem;
  padding: 0.625rem 1rem;
  margin-inline: 1rem;
  background: white;
  font-size: 0.8rem;
}

.selectQA:focus {
  border-color: #007bff;
}

.submitQA {
  background: #273c97;
  color: #ffffff;
  padding: 0.6rem 1rem;
  border-radius: 10px;
  width: 40%;
  border: none;
  font-weight: 500;
  font-size: 0.9rem;
}

.accordionQAContainer {
  width: 100%;
  padding: 1.25rem 2rem;
  background: #f5f5f5;
  border-radius: 0 0 20px 20px;
}

.accordionQA {
  padding: 0.25rem;
  border-radius: 0.5rem !important;
  margin-bottom: 0.75rem;
}

.accordionHeader {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  width: 100%;
}

.accordionHeader h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
}

.disabledAccordion {
  pointer-events: none;
}

.disabledSummary {
  cursor: default;
}
@media screen and (max-width: 40rem) {
  .selectQA {
    margin-top: 10px;
    margin-left: 0px !important;
    /* margin: auto; */
  }
}
