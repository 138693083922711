/* Webiners.module.css */

.webinarCard {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  width: 47%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.webinarCard h3 {
  margin: 0;
  font-size: 1.25rem;
}

.webinarCard p {
  margin: 5px 0;
  color: #828282;

  display: flex;
  align-items: center;
  margin-right: 10px;
}

.webinarButton {
  /* background-color: ; */
  background: #273c97;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  width: 75%;
  margin: auto;
}

.webinarButton:hover {
  background-color: #273c97;
}

.calenderIcons {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 15px;
}

/* Filter Dropdown */
.filterContainer {
  margin-inline-start: auto;
  margin-bottom: 20px;
  position: relative;
  width: fit-content;
}

.filterDropdown {
  border: 1px solid #ddd;
  padding: 10px 30px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #fff;
  color: #273c97;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.arrowDown {
  margin-left: 5px;
  font-size: 12px;
}

.filterOptions {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 5px;
  z-index: 1000;
}

.filterOptions p {
  padding: 10px 20px;
  margin: 0;
  cursor: pointer;
  color: #273c97;
}

.filterOptions p:hover {
  background-color: #f0f0f0;
}

.dashboardLayout {
  padding: 10px;
}

.Feedback {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Feedback button {
  padding: 8px;
  background-color: #ffc107;
  border: 1px solid #ffc107;
  border-radius: 10px;
  cursor: pointer;
}

.Feedback h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

.calendarIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 20; */
}

.buttonSubmit {
  background-color: #273c97 !important;
  color: white !important;
  border-radius: 5px !important;
  padding: 5px;
}

.buttonCancel {
  color: #273c97 !important;
}
@media screen and (max-width: 40rem) {
  .webinarCards {
    flex-direction: column;
  }
  .webinarCard {
    width: 100%;
  }
}
