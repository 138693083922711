.courseContainer {
  padding: 20px;
  color: #333;
}

.searchBar {
  margin-bottom: 20px;
}

.searchBar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.exitButton {
  display: block;
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.videoContainer {
  position: relative;
  margin-bottom: 20px;
}

.navigateButton {
  position: absolute;
  top: 75%;
  transform: translateY(-75%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  left: 15%;
}

.navigationDiv {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 50%;
}

.navigateButton.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.navigateButton:last-of-type {
  right: 32%;
}

.overviewSection {
  margin-top: 20px;
}

.overviewSection h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f7ff;
  text-align: left;
  width: fit-content;
}

.overviewSection p {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
}

@media screen and (max-width: 40rem) {
  .mobileArrow {
    padding: 1px !important;
  }

  .mobileArrow svg {
    width: 2.2rem;
    height: 2.2rem;
  }

  .courseContainer {
    padding: 0px;
  }
}