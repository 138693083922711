.tabContent {
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.passwordForm,
.supportForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.passwordForm label,
.supportForm label {
  font-size: 14px;
  color: #333;
}

.passwordForm input,
.supportForm input,
.supportForm textarea {
  width: 40%;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: transparent;
  border-radius: 5px;
  font-size: 14px;
}

.resetButton,
.requestButton {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #273c97;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.inputError {
  border: 1px solid red;
}

.successPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 1.5rem;
  border: 1px solid #ddd;
  box-shadow: 5px 5px 0.5rem rgb(66, 122, 66);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

@media screen and (max-width: 40rem) {
  .passwordForm input,
  .supportForm input,
  .supportForm textarea {
    width: auto;
  }

  .resetButton,
  .requestButton {
    padding: 10px 20px;
    font-size: 1.3rem;
  }
}
