@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --gray-44: #444444;
  --gray-55: #555555;
  --gray-c0: #c0c0c0;
  --gray-d9: #d9d9d9;
  --gray-ea: #eaeaea;
  --white-f5: #f5f5f5;
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  font-size: 16px;
  --bg--blue-color: #273c97;
}

@media (max-width: 1280px) {
  :root {
    font-size: 13px;
  }
}

@media (max-width: 992px) {
  :root {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  :root {
    font-size: 11px;
  }
}

body * {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.custom-container {
  padding-inline: 5%;
  margin: auto;
  position: relative;

  @media screen and (max-width: 1200px) {
    padding-inline: 5%;
  }
}

.select-input {
  @apply py-2 px-4 bg-white border border-gray-350 rounded-lg appearance-none focus:border-gray-7e bg-no-repeat bg-[95%_center] bg-[length:0.8rem] cursor-pointer;
}

.cover-image {
  @apply w-full h-full object-contain object-center;
}

.btn-primary {
  @apply text-white text-center rounded-lg bg-primary hover:bg-primary-light transition-all duration-300 ease-in-out;
}

.btn-secondary {
  @apply text-primary text-center rounded-lg bg-white border border-solid border-primary hover:bg-gray-250 transition-all duration-300 ease-in-out;
}

.circle {
  @apply aspect-square rounded-[50%] overflow-hidden;
}

.absolute-center {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

input,
textarea {
  border: none;
  outline: none;
  background-color: transparent;
  border-style: solid;

  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  font-size: inherit;
  color: inherit;
}

a,
button {
  cursor: pointer;
}

button {
  all: unset;
  display: inline-block;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: center;
  background-color: transparent;
  border: none;
  padding: 0;
  box-sizing: border-box;
}

h2 {
  font-size: 1.25rem;
  font-weight: 400;
}
.input input {
  border: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
  appearance: textfield;
}

.react-date-picker__wrapper,
.react-time-picker__wrapper {
  border: none !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-date-picker__inputGroup__input:invalid,
.react-time-picker__inputGroup__input:invalid {
  background: none !important;
}

/* Custom CSS for progress bar */
/* 5 Stars Progress */
.five-stars-progress::-webkit-progress-value {
  background: linear-gradient(270deg, #9be49e 0%, #4caf50 99.18%);
}

.five-stars-progress::-moz-progress-bar {
  background: linear-gradient(270deg, #9be49e 0%, #4caf50 99.18%);
}

/* 4 Stars Progress */
.four-stars-progress::-webkit-progress-value {
  background: linear-gradient(90deg, #90ee90 0%, #7def9d 100%);
}

.four-stars-progress::-moz-progress-bar {
  background: linear-gradient(90deg, #90ee90 0%, #7def9d 100%);
}

/* 3 Stars Progress */
.three-stars-progress::-webkit-progress-value {
  background: #ffd700;
}

.three-stars-progress::-moz-progress-bar {
  background: #ffd700;
}

/* 2 Stars Progress */
.two-stars-progress::-webkit-progress-value {
  background: linear-gradient(270deg, #fcae73 0%, #f79042 99.18%);
}

.two-stars-progress::-moz-progress-bar {
  background: linear-gradient(270deg, #fcae73 0%, #f79042 99.18%);
}

/* 1 Star Progress */
.one-star-progress::-webkit-progress-value {
  background: linear-gradient(90deg, #ff4c4c 0%, #c22d2b 100%);
}

.one-star-progress::-moz-progress-bar {
  background: linear-gradient(90deg, #ff4c4c 0%, #c22d2b 100%);
}

.custom-dialog {
  display: none;
}
.custom-dialog[open] {
  display: flex;
  flex-direction: column;
}
