.searchBar {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.searchInput {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.filterButton {
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.joinButton {
  background-color: #ffc107;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.joinButton svg {
  color: #ddd !important;
}

.statusTiles {
  /* width: 100%; */
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding-inline: 1.5rem;
  margin-bottom: 1.75rem;
}

.tile {
  /* width: fit-content; */
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 14px 42px 0px #080f340f;
}

.tileLabel {
  font-size: 0.85rem;
  color: #202020;
  font-weight: 600;
  margin-top: 0.25rem;
}

.continueWatching {
  margin-top: 20px;
}

.courseCard {
  display: flex;
  gap: 15px;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
}

.courseImage img {
  width: 10rem;
  height: 100%;
  border-radius: 8px;
}

.courseInfo {
  flex: 1;
}

.courseInfo h3 {
  font-size: 1.1em;
  margin: 0;
}

.instructorInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
}

.instructorImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.completed {
  color: #4caf50;
  font-weight: 500;
  font-size: 0.8rem;
}

.notCompleted {
  color: #5f5f5f;
  font-weight: 400;
  font-size: 0.8rem;
}

.notStarted {
  color: red;
  font-weight: 400;
  font-size: 0.8rem;
}

@media screen and (max-width: 40rem) {
  .statusTiles {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
