.settingsContainer {
  padding: 20px;
}

.searchInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.tabButton {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: bold;
  color: #666;
}

.active {
  color: #273c97;
  border-bottom: 2px solid #273c97;
}

.tabContent {
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  /* width: fit-content; */
}

.uploadBox {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #273c97;
  padding: 40px;
  border-radius: 8px;
  text-align: center;
}

.uploadPlaceholder span {
  font-size: 16px;
  font-weight: bold;
  color: #273c97;
}

.uploadPlaceholder p {
  color: #888;
}

.passwordForm,
.supportForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.passwordForm label,
.supportForm label {
  font-size: 14px;
  color: #333;
}

.passwordForm input,
.supportForm input,
.supportForm textarea {
  width: 40%;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: transparent;
  border-radius: 5px;
  font-size: 14px;
}

.supportForm textarea {
  resize: vertical;
}

.resetButton,
.requestButton {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background-color: #273c97;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 40rem) {
  .uploadBox {
    padding: 1.8rem;
  }

  .tabButton {
    padding: 0.3rem;
    font-size: 1.3rem;
  }
}