.dashboardOverview {
  padding: 20px;
  position: relative;
}

.statusDropdown {
  margin-bottom: 20px;
}

.taskContainer {
  display: flex;
  justify-content: center;
}

.taskCard {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 40rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.description {
  color: #666;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.deadline {
  color: #666;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.uploadSection {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.uploadHint {
  font-size: 12px;
  color: #666;
}

.submitButton {
  background-color: #273c97 !important;
  color: #fff;
  width: 40%;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  margin: auto;
}

.reviewSection {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.reviewComment,
.reviewDegree {
  flex: 1;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
}

.reviewDegree p {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}