.container {
  /* padding: 20px; */
  max-width: 65rem;
  margin: 0 auto;
}

.searchBar {
  margin-bottom: 20px;
}

.header {
  margin-bottom: 20px;
  font-weight: bold;
  color: #273c97;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 20px !important;
}

.header svg {
  font-size: 50px;
}

.infoSections {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 30px;
}

.infoCard {
  border-radius: 10px;
  text-align: center;
  width: 50%;
}

.icon {
  color: #273c97;
  font-size: 40px;
  margin-bottom: 10px;
}

.uploadSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ddd;
  padding: 40px;
  border-radius: 10px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 50%;
  margin: auto;
}

.uploadSection:hover {
  background-color: #e6e6e6;
}

.uploadIcon {
  color: #273c97;
}

.uploadText {
  color: #666;
  margin-top: 10px;
  font-weight: bold;
}

.infoCard h6 {
  color: #273c97;
  font-weight: bolder;
}

.firstbody {
  background-color: #f7f9fb;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px !important;
}
.returnData {
  border: 1px solid #f7f9fb;
  padding: 50px;
  background-color: #f7f9fb;
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  white-space: pre-wrap;
}

@media screen and (max-width: 40rem) {
  .infoSections {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .infoCard {
    width: 80%;
  }
  .uploadSection {
    width: 80%;
  }
}
.buttonStyles{
  padding: 0px !important;

}